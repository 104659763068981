var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { computed, makeObservable } from 'mobx';
import { Page, } from './StepController';
import { cancelPaymentWithWire, createWallet, getOrderPaymentInfo, paymentWithWire, sendEmail, sendTargetAddress, userDetails, } from '../methods/order';
import { MessageTypes } from 'common';
import { sendMessageToHost } from '../../utils/sendMessageToHost';
import { getDefaultAmounts, } from './ExchangeController';
import { ExchangeInfoView } from '../ViewModels/ExchangeInfoView';
import { BLOCKED_USER_ERROR_CODE } from '../apiIntegrations/const';
import { storage } from '../storage';
import { saveAddress } from '../savedWalletAddress';
import { removeUserToken } from '../userToken';
import { getNonEditableDefaultValue } from '../../View/Contexts/SettingsContext';
var OrderFacade = /** @class */ (function () {
    function OrderFacade(stepController, userController, orderController, exchangeController, paymentController, currencies, defaultValues) {
        var _this = this;
        this.stepController = stepController;
        this.userController = userController;
        this.orderController = orderController;
        this.exchangeController = exchangeController;
        this.paymentController = paymentController;
        this.currencies = currencies;
        this.defaultValues = defaultValues;
        this.setPaymentCardType = function (type) {
            _this.paymentController.setPayment(type);
        };
        this.checkErrorForBlockUser = function (e) {
            var _a, _b, _c;
            if (((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status) === BLOCKED_USER_ERROR_CODE) {
                _this.stepController.updateStepInfo({
                    nextStep: {
                        step: Page.BLOCKED,
                    },
                });
            }
            throw ((_c = (_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) || 'Something went wrong';
        };
        this.updateStepsInfo = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.orderController.getOrder()];
                    case 1:
                        _a.sent();
                        if (this.order && 'currentStep' in this.order) {
                            this.stepController.updateStepInfo({
                                nextStep: this.order.currentStep,
                                stepsHistory: this.order.stepsHistory,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.createOrderWithToken = function (userToken, onError) { return __awaiter(_this, void 0, void 0, function () {
            var targetAddressFrontPrefill, userPhoneFrontPrefill, userEmailFrontPrefill;
            var _this = this;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                targetAddressFrontPrefill = getNonEditableDefaultValue((_a = this.defaultValues) === null || _a === void 0 ? void 0 : _a.targetAddress);
                userPhoneFrontPrefill = (_c = (_b = this.defaultValues) === null || _b === void 0 ? void 0 : _b.phone) === null || _c === void 0 ? void 0 : _c.value;
                userEmailFrontPrefill = (_e = (_d = this.defaultValues) === null || _d === void 0 ? void 0 : _d.email) === null || _e === void 0 ? void 0 : _e.value;
                return [2 /*return*/, userDetails(userToken)
                        .then(function (res) {
                        var isPhonesEqual = !userPhoneFrontPrefill ||
                            res.phone.replaceAll('+', '') ===
                                userPhoneFrontPrefill.replaceAll('+', '');
                        var isEmailsEqual = !userEmailFrontPrefill || res.email === userEmailFrontPrefill;
                        if (!isPhonesEqual || !isEmailsEqual) {
                            throw new Error('User data from token is not equal to default values');
                        }
                        _this.userController.setField(res.phone, 'phone');
                        _this.userController.setField(res.email, 'email');
                    })
                        .then(function () {
                        return _this.createOrder({
                            userInfo: { userToken: userToken, targetAddress: targetAddressFrontPrefill },
                        });
                    })
                        .catch(function () {
                        removeUserToken();
                        onError();
                    })];
            });
        }); };
        this.createOrder = function (_a) {
            var userInfo = _a.userInfo, captcha = _a.captcha, extendedCaptcha = _a.extendedCaptcha;
            return __awaiter(_this, void 0, void 0, function () {
                var req, res, e_1;
                var _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 3, , 4]);
                            req = this.exchangeController.exchangeDto;
                            return [4 /*yield*/, this.orderController.createOrder(userInfo, req, captcha, extendedCaptcha)];
                        case 1:
                            res = _c.sent();
                            storage.TOKEN.set(res.token);
                            storage.USER_UID.set(res.userUid);
                            storage.ORDER_ID.set(res.orderUid);
                            this.exchangeController.stopWatching();
                            return [4 /*yield*/, this.orderController.startWatching()];
                        case 2:
                            _c.sent();
                            this.stepController.updateStepInfo(res);
                            if (userInfo === null || userInfo === void 0 ? void 0 : userInfo.phone) {
                                this.userController.setField(userInfo === null || userInfo === void 0 ? void 0 : userInfo.phone, 'phone');
                            }
                            if (userInfo.email) {
                                this.userController.setField(userInfo.email, 'email');
                            }
                            if (userInfo.targetAddress) {
                                this.userController.setField(userInfo.targetAddress, 'address');
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _c.sent();
                            if (((_b = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _b === void 0 ? void 0 : _b.status) !== BLOCKED_USER_ERROR_CODE &&
                                this.orderController.order) {
                                this.orderController.removeOrder();
                                this.orderController.stopWatching();
                                this.exchangeController.startWatching();
                            }
                            this.checkErrorForBlockUser(e_1);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        };
        this.getPaymentInfo = function (method) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getOrderPaymentInfo(this.paymentController.paymentForService, method)];
                    case 1:
                        res = _a.sent();
                        this.stepController.updateStepInfo({
                            nextStep: __assign({ step: Page.PAYMENT }, res.data),
                            stepsHistory: __spreadArray(__spreadArray([], this.stepController.stepsHistory, true), [
                                { step: Page.CHECK_ORDER },
                            ], false),
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.checkErrorForBlockUser(err_1);
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.initiateWirePayment = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, paymentWithWire()];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.orderController.getOrder()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, res.data];
                    case 3:
                        err_2 = _a.sent();
                        this.checkErrorForBlockUser(err_2);
                        throw err_2;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.cancelWirePayment = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, cancelPaymentWithWire()];
                    case 1:
                        res = _a.sent();
                        this.stepController.updateStepInfo(res.data);
                        return [4 /*yield*/, this.orderController.getOrder()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        this.checkErrorForBlockUser(err_3);
                        throw err_3;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.sendEmail = function (dto) { return __awaiter(_this, void 0, void 0, function () {
            var res, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, sendEmail(dto)];
                    case 1:
                        res = _a.sent();
                        if ('email' in dto) {
                            this.userController.setField(dto.email, 'email');
                        }
                        // todo: Нужно научить принимать разные типы
                        this.userController.setField("".concat(dto.subscribe ? 'true' : ''), 'subscribe');
                        this.stepController.updateStepInfo(res.data);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.checkErrorForBlockUser(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.resendEmail = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.sendEmail({
                        email: this.userController.email,
                        subscribe: !!this.userController.subscribe,
                    })];
            });
        }); };
        this.sendAddress = function (address) { return __awaiter(_this, void 0, void 0, function () {
            var res, currency, e_3;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, sendTargetAddress({ address: address })];
                    case 1:
                        res = _b.sent();
                        this.userController.setField(address, 'address');
                        sendMessageToHost({
                            type: MessageTypes.TARGET_ADDRESS_ENTERED,
                            payload: {
                                address: address,
                            },
                        });
                        currency = (_a = this.orderController.order) === null || _a === void 0 ? void 0 : _a.amounts.to.currency;
                        saveAddress(address, currency);
                        this.stepController.updateStepInfo(res.data);
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _b.sent();
                        this.checkErrorForBlockUser(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.createWallet = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, currency, e_4;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, createWallet()];
                    case 1:
                        res = _b.sent();
                        if (res.data.address) {
                            sendMessageToHost({
                                type: MessageTypes.TARGET_ADDRESS_ENTERED,
                                payload: {
                                    address: res.data.address,
                                },
                            });
                            currency = (_a = this.orderController.order) === null || _a === void 0 ? void 0 : _a.amounts.to.currency;
                            saveAddress(res.data.address, currency);
                        }
                        this.stepController.updateStepInfo(res.data);
                        this.userController.setField(res.data.address, 'address');
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _b.sent();
                        this.checkErrorForBlockUser(e_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (this.orderController.order) {
            this.orderController.startWatching();
        }
        else {
            this.exchangeController.startWatching();
        }
        makeObservable(this, {
            order: computed,
            exchangeInfo: computed,
            userInfo: computed,
            paymentCardType: computed,
        });
    }
    Object.defineProperty(OrderFacade.prototype, "paymentCardType", {
        get: function () {
            return this.paymentController.type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderFacade.prototype, "errors", {
        get: function () {
            return {
                estimate: this.exchangeController.error,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderFacade.prototype, "userInfo", {
        get: function () {
            return {
                address: this.userController.address,
                email: this.userController.email,
                phone: this.userController.phone,
                subscribe: this.userController.subscribe,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderFacade.prototype, "order", {
        get: function () {
            return this.orderController.order;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderFacade.prototype, "NFTInfo", {
        get: function () {
            var _a, _b;
            return (((_b = (_a = this.orderController.order) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.smartContractData) ||
                this.exchangeController.smartContractData);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderFacade.prototype, "exchangeInfo", {
        get: function () {
            var _a;
            return new ExchangeInfoView(this.orderController.order ||
                this.exchangeController.exchangeInfo || {
                amounts: getDefaultAmounts(this.exchangeController.exchangeDto, this.currencies.currenciesMap),
            }, (_a = this.exchangeController.exchangeDto.metadata) === null || _a === void 0 ? void 0 : _a.smartContractData.name);
        },
        enumerable: false,
        configurable: true
    });
    OrderFacade.prototype.changeExchangeInfo = function (value) {
        this.orderController.stopWatching();
        this.orderController.removeOrder();
        return this.exchangeController.changeRequest(value);
    };
    OrderFacade.prototype.setStepsInfo = function (info) {
        this.stepController.updateStepInfo(info);
    };
    OrderFacade.prototype.setStep = function (step) {
        this.stepController.setStep(step);
    };
    return OrderFacade;
}());
export { OrderFacade };
