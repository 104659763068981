var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useLayoutEffect, useState, } from 'react';
import { useMerchant } from './MerchantContext';
export var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["DIRECT_PAYMENT"] = "WIDGET_DIRECT_PAYMENT";
    FeatureFlag["CUSTOM_KYC"] = "WIDGET_CUSTOM_KYC";
    FeatureFlag["WALLET_CREATION"] = "WIDGET_CREATE_WALLET";
    FeatureFlag["ENABLE_WHITELABEL"] = "WIDGET_ENABLE_WHITELABEL";
    FeatureFlag["ENABLE_CRYPTOLESS_MODE"] = "WIDGET_ENABLE_CRYPTOLESS_MODE";
})(FeatureFlag || (FeatureFlag = {}));
function getFeatureFlagsRecord(flags) {
    if (flags === void 0) { flags = []; }
    return Object.values(FeatureFlag).reduce(function (acc, flag) {
        acc[flag] = flags.includes(flag) || false;
        return acc;
    }, {});
}
var initialFlags = getFeatureFlagsRecord();
var FeatureFlagContext = createContext(initialFlags);
export var FeatureFlagProvider = function (_a) {
    var children = _a.children;
    var _b = useState(initialFlags), flags = _b[0], setFlags = _b[1];
    var merchant = useMerchant();
    useLayoutEffect(function () {
        var updatedFlags = getFeatureFlagsRecord(merchant.features);
        setFlags(updatedFlags);
    }, [merchant.features]);
    return (_jsx(FeatureFlagContext.Provider, __assign({ value: flags }, { children: children })));
};
export function useFeatureFlag(flag) {
    var flags = useContext(FeatureFlagContext);
    return flags[flag];
}
