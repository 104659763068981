var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import { createOrder, getCurrentOrder } from '../methods/order';
import { storage } from '../storage';
var MIN_UPDATE_TIME = 5000;
var OrderController = /** @class */ (function () {
    function OrderController(order, externalId) {
        var _this = this;
        this.removeOrder = function () {
            storage.ORDER_ID.remove();
            storage.TOKEN.remove();
            storage.USER_UID.remove();
            _this.order = undefined;
        };
        this.order = order;
        this.error = false;
        this.ttl = MIN_UPDATE_TIME;
        this.externalId = externalId;
        makeObservable(this, {
            order: observable,
            getOrder: action,
        });
    }
    OrderController.prototype.getOrder = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res_1, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clearTimeout(this.timeout);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, getCurrentOrder()];
                    case 2:
                        res_1 = _a.sent();
                        // NOTE: for some reason can't update `this.order` directly inside this action,
                        // but wrapping with `runInAction` works fine
                        runInAction(function () {
                            _this.order = res_1.data;
                            _this.error = false;
                            _this.ttl = Math.max(res_1.data.rateTtl * 1000, MIN_UPDATE_TIME);
                        });
                        return [2 /*return*/, this.order];
                    case 3:
                        e_1 = _a.sent();
                        this.error = true;
                        return [3 /*break*/, 5];
                    case 4:
                        this.timeout = setTimeout(function () {
                            _this.getOrder();
                        }, this.ttl);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    OrderController.prototype.startWatching = function () {
        return this.getOrder();
    };
    OrderController.prototype.stopWatching = function () {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    };
    OrderController.prototype.createOrder = function (userData, dto, captcha, extendedCaptcha) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, createOrder(__assign({ captcha: captcha, user: {
                                phone: userData.phone,
                                email: userData.email,
                                token: userData.userToken,
                            }, targetAddress: userData.targetAddress, externalId: this.externalId, extendedCaptcha: extendedCaptcha }, dto))];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    return OrderController;
}());
export { OrderController };
